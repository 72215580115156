<script>
import proofViewNavigation from "./proof-view-navigation.vue";
import proofViewSideBarLeft from "./proof-view-sidebar-left.vue";
import proofViewSideBarRight from "./proof-view-sidebar-right.vue";
import proofViewZoomTools from "./proof-view-zoom-tools.vue";
import proofViewPagination from "./proof-view-pagination.vue";
import ProofViewNewAnnotations from "./proof-view-new-annotations";
import ProofViewCanvas from "./proof-view-canvas";
import {scroller} from "vue-scrollto/src/scrollTo";
import ProofViewTools from "./proof-view-tools";
import { PROOF_VIEW_MODES, TYPES_ENUM, DOC_TYPES, ANNOTATION_TYPES } from "../../utils/constants";

const _ = require('lodash');

const { PROOF_OWNER_MODE } = PROOF_VIEW_MODES;
const { VIDEO, IMAGE, HTML, AUDIO, DOCUMENT } = DOC_TYPES;
const { FUNCTION } = TYPES_ENUM;
const { DRAW, POINT } = ANNOTATION_TYPES;
const firstScrollTo = scroller();
const secondScrollTo = scroller();
const FileProcessedEventType = 62;
const MIN_DATE_TIME = "0001-01-01T00:00:00";
const RESET = 'reset';

export default {
  name: 'proofView',
  components: {
    ProofViewTools,
    proofViewNavigation,
    proofViewSideBarLeft,
    proofViewSideBarRight,
    ProofViewCanvas,
    ProofViewNewAnnotations,
    proofViewZoomTools,
    proofViewPagination
  },
  props: {
    label: {
      type: String,
      required: true
    },
    mode: {
      type: Number,
      required: true
    },
    accessDenied: {
      type: Boolean
    },
    accessDeniedMessage: {
      type: String
    },
    commentFileApi: {
      type: String,
      required: true
    },
    curUser: {},
    actives: {},
    events: {},
    curApprover: {},
    curAccount: {},
    approverFeedback: {},
    approverPublicId: {},
    viewMode: {
      type: Number,
    },
    isReviewMode: {
      type: Boolean,
      default: true
    },
    isCompareMode: {
      type: Boolean,
      default: false
    },
    topNavBarOffsetHeight: {
      type: Number,
      default: 0
    },
    publicId: {},
    curLang: {},
    curDisplayLang: {},
    showApprovedButton: {},
    curLogo: {},
    proofViewVersion: 'modern',
  },
  // metaInfo () {
  //   return {
  //     title: 'this is a test title',
  //     meta: [
  //       {
  //         key: 'og:title',
  //         name: 'og:title',
  //         content: 'this is a test value',
  //       },
  //       {
  //         key: 'og:image',
  //         name: 'og:image',
  //         content: this.curLogo,
  //       },
  //     ]
  //   }
  // },
  data() {
    return {
      width: 0,
      menu: true,
      proof: null,
      goToPageInputValue: -1,
      curFile: null,
      isMobile: window.innerWidth < 991,
      isSmallMobileView: window.innerWidth < 576,
      isMouseOver: false,
      steps: [
        {
          target: "#review-available-tools",
          label: "review_available_tools",
          params: {
            enableScrolling: false,
          },
          disableOnFileTypes: [AUDIO]
        },
        {
          target: "#review-available-files",
          label: "review_available_files",
          params: {
            enableScrolling: false,
          },
        },
        {
          target: "#review-canvas",
          label: "review_your_canvas",
          params: {
            enableScrolling: false,
            placement: "bottom",
          },
        },
        {
          target: "#approval-buttons",
          label: "approval_button_group",
          params: {
            enableScrolling: false,
            placement: "bottom",
          },
          disableOnModes: [2],
        },
      ],
      tourCallbacks: {
        onStop: function () {
          window.$A.ReviewService.MarkTourCompleted();
        },
      },
      tourLabels: {},
      isRecording: false,
      colorPerUserId: {},
      curAnnotationType: {
        docTypes: [DOCUMENT, VIDEO, AUDIO, IMAGE, HTML],
        type: POINT,
        title: () => this.getLabel('review_screen_labels', 'anno_single_click'),
        image: "/img/icon-doubleclick.svg",
        cursor: "/img/icon-doubleclick.png",
      },
      proofNavHeight: 0,
      proofToolsHeight: 0,
      proofCommentsWidth: 0,
      proofFilesWidth: 0,
      editingAnnotationId: 0,
      activeEditorAnnotationId: 0,
      isLiveProofView: false,
      liveProofActiveUrl: null,
      liveProofSizeSelected: {
        size: 0,
        label: () => ""
      },
      liveProofPredefinedSizes: [
        {
          size: window.innerWidth < 576 ? window.innerWidth - 30 : 500,
          label: () => this.getLabel('review_screen_labels', "web_x_small")
        },
        {
          size: 576,
          label: () => this.getLabel('review_screen_labels', "web_small")
        },
        {
          size: 768,
          label: () => this.getLabel('review_screen_labels', "web_medium")
        },
        {
          size: 992,
          label: () => this.getLabel('review_screen_labels', "web_large")
        },
        {
          size: 1200,
          label: () => this.getLabel('review_screen_labels', "web_xl")
        },
        {
          size: 1400,
          label: () => this.getLabel('review_screen_labels', "web_xxl")
        },
        {
          size: 1920,
          label: () => this.getLabel('review_screen_labels', "web_xxxl")
        }
      ],
      liveProofCommentsEnabled: true,
      colorPickerColor: "#D44D68",
      drawToolLineWidth: 5,
      followingApprover: null,
      followingTargetId: null,
      isDragEnabled: false,
      isThirdWheelZoomActive: false,
    };
  },
  async mounted() {
    this.handleResizeWindow();
    this.$root.$on("player:progress:change", this.updateProgress);
    this.disableContextMenu();
    window.onresize = this.handleResizeWindow();
    this.handleKeyDown();
  },
  methods: {
    loadTourLabels: function(){
      this.tourLabels = {
        'buttonSkip': this.getLabel('tour_labels', 'skip_tour_btn'),
        'buttonPrevious': this.getLabel('tour_labels', 'previous_btn'),
        'buttonNext': this.getLabel('tour_labels', 'next_btn'),
        'buttonStop': this.getLabel('tour_labels', 'finish_btn'),
      };
    },
    handleKeyDown: function () {
      let self = this;
      window.document.addEventListener("keydown", (event) => {
        if (self.isModalOpen || self.editingAnnotationId != 0 || (!self.isReviewMode && !self.isMouseOver) || !self.curFile) return;
        switch (event.key) {
          case "F1":
            self.toggleZoomMode();
            return;
          case "ArrowRight":
            event.preventDefault();
            self.GoToNextPage();
            return;
          case "ArrowLeft":
            event.preventDefault();
            self.GoToPreviousPage();
            return;
          case "ArrowUp":
            if (!window.selectingUserToTag) {
              event.preventDefault();
              self.GoToPreviousProofFile();
            }
            return;
          case "ArrowDown":
            if (!window.selectingUserToTag) {
              event.preventDefault();
              self.GoToNextProofFile();
            }
            return;
          case " ":
            if (!document.activeElement.isContentEditable) {
              self.$refs.proofCanvas.pauseOrResumePlay(true);
            }
        }
      });
    },
    updateProgress(progressUpdate) {
      if (this.curFile)
        this.curFile.player.curPlayerTime = progressUpdate;
    },
    viewProofVersion: async function (proofVersionId, proof) {
      if (proofVersionId === 'compare') {
        return this.$emit('on-compare');
      }

      if (_.get(this.proof, 'currentVersion.id') === proofVersionId) {
        return;
      }
      if (proofVersionId === undefined) proofVersionId = 0;

      if(proof) return this.SetCurrentProof(proof, true);

      if (this.curUser !== null) {
        this.SetCurrentProof(await this.$A.ProofService.GetFullProof(
            this.proof.id,
            proofVersionId
        ), true)
      }
      if (this.curApprover !== null) {
        this.SetCurrentProof(await this.$A.ReviewService.GetProofToReview(
          proofVersionId
        ), true);
      }
    },
    getColor: function (userId, luminosity = "dark") {
      if (this.colorPerUserId[userId]) {
        return this.colorPerUserId[userId]
      }
      let seed = Object.keys(this.colorPerUserId).length * 1000;
      let color = this.$randomColor({luminosity: luminosity, seed: seed})
      this.colorPerUserId[userId] = color;
      return color;
    },
    SetCurrentProof(proof, loadFile) {
      let self = this;
      if (loadFile === undefined)
        loadFile = false

      if (_.get(proof, 'message') === "Token Expired") {
        this.$A.AlertUser(
            this.$A.ReviewService.Lang("link-expired"),
            "warning",
            20000
        );
        return;
      }

      const proofId = _.get(proof, 'id', 0)
      if (!proofId) return;

      this.editingCommentId = 0;
      this.editingAnnotationId = 0;
      this.curFile = undefined;
      this.curAnnotation = null;

      let updateCdnLinks = function (cf) {
        if (
            cf.thumbnailFileKey != null &&
            cf.thumbnailFileKey.indexOf("cdn.ashoreapp.com") === -1 &&
            cf.thumbnailFileKey.length > 3 &&
            cf.thumbnailFileKey.indexOf("static") === -1 &&
            !cf.thumbnailFileKey.startsWith("public/icon")
        ) {
          cf.thumbnailFileKey =
              "https://cdn.ashoreapp.com/" + cf.thumbnailFileKey;
        }
        if (
            cf.convertedFileKey != null &&
            cf.convertedFileKey.indexOf("cdn.ashoreapp.com") === -1 &&
            cf.convertedFileKey.length > 3 &&
            cf.convertedFileKey.indexOf("static") === -1 &&
            !cf.convertedFileKey.startsWith("public/icon")
        ) {
          cf.convertedFileKey =
              "https://cdn.ashoreapp.com/" + cf.convertedFileKey;
        }
        if (
            cf.originalFileKey != null &&
            cf.originalFileKey.indexOf("cdn.ashoreapp.com") === -1 &&
            cf.originalFileKey.length > 3 &&
            cf.originalFileKey.indexOf("static") === -1 &&
            !cf.originalFileKey.startsWith("public/icon")
        ) {
          cf.originalFileKey = "https://cdn.ashoreapp.com/" + cf.originalFileKey;
        }

        if (cf.convertedFileKey === "") {//html doesn't set the conversion key right fix server side
          cf.convertedFileKey = cf.originalFileKey;
        }
      };

      const fileConversionProofEvents = _.get(proof, 'proofEvents',[]).filter((event) => event.eventType === FileProcessedEventType);

      const markFileAsConverted = (versionFile) => {
        versionFile.curTimer = "done";
        versionFile.converting = false;
      }
      
      const updateFilesAfterConversion = (versionFile) => {
        const convertedFile = fileConversionProofEvents.find((each) => each.appEventObject.file.id === versionFile.id);

        if (convertedFile) {
          markFileAsConverted(versionFile);
          versionFile.convertedFileKey = _.get(convertedFile, 'appEventObject.file.convertedFileKey', '');
          versionFile.conversionJobCompletedAt = _.get(convertedFile, 'appEventObject.file.conversionJobCompletedAt', null);
        } else if (versionFile.conversionJobCompletedAt !== null && versionFile.conversionJobCompletedAt !== MIN_DATE_TIME) {
          // Handle existing file's conversion status, as they don't have FileProcessedEventType
          markFileAsConverted(versionFile);
        } else if (versionFile.conversionJobQueuedAt !== null && versionFile.conversionJobQueuedAt === MIN_DATE_TIME) {
          // Handle case where conversion job was queued at min date time
          versionFile.converting = false;
        } else {
          let t = self.$A.TimeSinceDateTime(versionFile.conversionJobQueuedAt);
          versionFile.curTimer = `${t.minutes}:${t.seconds}`;
          versionFile.conversionTimerMin = t.minutes;
          versionFile.converting = true;
        }
        updateCdnLinks(versionFile);
      };

      let curProofVersionFiles = proof.currentVersion.versionFiles
          ? proof.currentVersion.versionFiles : [];

      curProofVersionFiles.forEach(versionFile => {
        updateCdnLinks(versionFile);
        versionFile.startedLoading = 0;
        versionFile.zoom = 1;
        versionFile.zoomMode = 0;//0 is fill Height 1 is fill width
        versionFile.height = 0;
        versionFile.zoomHeight = 0;
        versionFile.rotation = 0;
        versionFile.width = 0;
        versionFile.zoomWidth = 0;
        versionFile.player = {
          curPlayerTime: 0
        };
        versionFile.pdf = {
          totalPages: 0,
          curPage: 0,
          pageToView: 1
        };
        versionFile.element = null;
        versionFile.loaded = versionFile.loaded || false;
        versionFile.src = (!versionFile.conversionJobQueuedAt) ? versionFile.originalFileKey : versionFile.convertedFileKey;
        versionFile.conversionTimerMin = 0;
        versionFile.curTimer = '';
        updateFilesAfterConversion(versionFile);
      })

      //set active proof
      this.proof = proof;

      this.$emit("proofLoaded", proof);
      this.$emit("version-changed", proof.currentProofVersionId);

      //set colors
      if (_.get(this.proof, 'currentVersion.currentStage.approvers')) {
        this.proof.currentVersion.currentStage.approvers.forEach(function (a) {
          if (a.color === undefined) {
            a.color = self.getColor(a.id);
          }

          if (self.curApprover !== null && self.curApprover.id === a.id) {
            self.curApprover.color = a.color;
          }
        });
        this.proof.currentVersion.currentStage.reviewers.forEach(function (a) {
          if (a.color === undefined) {
            a.color = self.getColor(a.id);
          }

          if (self.curApprover !== null && self.curApprover.id === a.id) {
            self.curApprover.color = a.color;
          }
        });
      }

      if (_.get(this.proof, 'currentVersion.sender')) {
        this.proof.currentVersion.sender.color = self.getColor(this.proof.currentVersion.sender.id);
      }

      if (this.curUser !== null && this.curUser.color === undefined) {
        this.curUser.color = self.getColor(this.curUser.id);
      }

      if (this.curApprover !== null && this.curApprover.color === undefined) {
        // this is only used when the approver has not been added to the list yet
        this.curApprover.color = self.curApprover.id;
      }

      if (this.proof !== null && this.proof.engagedUsers) {
        let curUserInListYet = false;
        this.proof.engagedUsers.forEach(function (a) {
          if (self.curUser !== null ? self.curUser.id === a.id : false) {
            curUserInListYet = true;
          }
          if (a.color === undefined) {
            a.color = self.getColor(a.id);
          }
        });

        if (!curUserInListYet && this.curUser !== null) {
          const name = _.get(self, 'curUser.name', '');
          const initials = name && name.match(/\b\w/g).join("");

          this.proof.engagedUsers.push({
            id: _.get(self, 'curUser.id', ''),
            name: name,
            initials: initials || "",
            color: _.get(self, 'curUser.color', ''),
          });
        }
      }

      //save current proof version id
      let defaultFileIdToSelect = this.$A.LoadDataCache("proof_version_id:" + this.curVersionId + "_fileId");

      let viewedFiles = this.$A.LoadDataCache("proof_version_id:" + this.curVersionId + "_viewed_files") || [];

      this.proof.currentVersion.versionFiles.forEach(f => {
        f.viewed = viewedFiles.indexOf(f.id) > -1;
      });

      //load id from link
      if (this.$route.query.pfid &&
          this.$route.query.pfid > 0 &&
          this.proof.currentVersion.versionFiles.find(
              (f) => f.id === this.$route.query.pfid
          ) !== undefined
      ) {
        defaultFileIdToSelect = this.$route.query.pfid;
      }

      if (loadFile) {
        let a = this.proof.currentVersion.versionFiles.find(i => i.id === defaultFileIdToSelect);
        if (a) {
          this.reviewFile(a);
        } else if (this.proof.currentVersion.versionFiles.length > 0) {
          this.reviewFile(this.proof.currentVersion.versionFiles[0]);
        }
      }

      this.loadTourLabels();
    },
    GoToNextProofFile() {
      if (this.proof && this.proof.currentVersion) {
        let curIndex = this.files.indexOf(this.curFile);
        curIndex++;
        if (this.files[curIndex]) {
          this.reviewFile(this.files[curIndex]);
        }
      }
    },
    GoToPreviousProofFile() {
      if (this.proof && this.proof.currentVersion) {
        let curIndex = this.files
            .indexOf(this.curFile)
        curIndex--;
        if (this.files[curIndex]) {
          this.reviewFile(this.files[curIndex]);
        }
      }
    },
    GoToPage(goToPageInputValue) {
      let newPageNumber = parseInt(goToPageInputValue);
      if (newPageNumber > 0 && newPageNumber <= this.curFile.pdf.totalPages) {
        this.$refs.proofCanvas.resetDrawing();
        this.$refs.proofCanvas.resetFilePositions();
        this.curFile.pdf.pageToView = newPageNumber;
        let d = document.getElementById(`${this.label}-review-container`);
        if (d.length > 0) {
          d[0].scrollTo(0, 0);
        }
      }
    },
    GoToNextPage() {
      this.$refs.proofCanvas.resetDrawing();
      this.$refs.proofCanvas.resetFilePositions();
      if ((this.curFile.pdf.pageToView + 1) <= this.curFile.pdf.totalPages) {
        this.curFile.pdf.pageToView = this.curFile.pdf.pageToView + 1;
      }
      let d = document.getElementById(`${this.label}-review-container`);
      if (d.length > 0) {
        d[0].scrollTo(0, 0);
      }
    },
    GoToPreviousPage() {
      this.$refs.proofCanvas.resetDrawing();
      this.$refs.proofCanvas.resetFilePositions();
      (this.curFile.pdf.pageToView > 1) ? this.curFile.pdf.pageToView-- : true;
      let d = document.getElementById(`${this.label}-review-container`);
      if (d.length > 0) {
        d[0].scrollTo(0, 0);
      }
    },
    getLabel: function (section, key) {
      return this.$A.LangService.getLabel(section, key);
    },
    resize: function () {
      const proofCanvas = _.get(this.$refs, 'proofCanvas');

      if (proofCanvas && typeof proofCanvas.resize === FUNCTION) proofCanvas.resize();
    },
    updateZoom: function (zoomLevel) {
      this.$refs.proofCanvas.updateZoom(zoomLevel);
    },
    rotate: function () {
      this.$refs.proofCanvas.rotate();
    },
    setRotation: function (rotation) {
      this.$refs.proofCanvas.setRotation(rotation);
    },
    toggleZoomMode: function () {
      this.$refs.proofCanvas.toggleZoomMode();
    },
    setZoomMode: function (mode) {
      this.$refs.proofCanvas.setZoomMode(mode);
    },
    validateCanComment: function () {
      if (this.archived) {
        this.$A.AlertUser("The Proof Is Archived, No Changes Can Be Made.");
        return false;
      }

      if (this.proof.blockCommentingAfterProofDecision && this.approverFeedback !== null) {
        this.$A.AlertUser(`${this.getLabel('approver_decision_modal_labels', 'approver_alert_after_submit')}`);
        return false;
      }

      return true;
    },
    editComment: function (a, c) {
      if (!this.validateCanComment()) return;
      this.editingCommentId = c.id;
      this.menu = true;
      let self = this;
      this.$nextTick().then(function () {
        let refs = self.$refs["editing_comment_redactor" + c.id];
        if (refs) {
          let redactorRef = refs[0];
          setTimeout(() => {
            redactorRef.setFocusEnd();
            redactorRef.setTextBody(c.text);
          }, 200);
        }
      });
    },
    updateComment: async function (c, newText) {
      if (!this.validateCanComment()) return;
      c.text = newText;
      let commentUpdated = await this.$A.AnnotationService.UpdateComment(c) || {};
      if (!_.isEmpty(commentUpdated) && commentUpdated.id > 0) {
        c = commentUpdated;
        this.editingCommentId = 0;
      }
    },
    deleteAnnotation: async function (a) {
      if (!this.validateCanComment()) return;
      if (!Number.isInteger(a.id)) {
        this.curFile.proofFileAnnotations.splice(this.curAnnotations.indexOf(a), 1);
        return;
      }
      let response = await this.$A.AnnotationService.DeleteAnnotation(a.id);
      if (response && response.success) {
        this.curFile.proofFileAnnotations.splice(this.curAnnotations.indexOf(a), 1);
      }
    },
    addAnnotation: function (a) {
      if (!this.validateCanComment()) return false;
      this.curFile.proofFileAnnotations.push(a);
      this.menu = true;
      return true;
    },
    deleteComment: async function (a, c) {
      if (!this.validateCanComment()) return;
      let response = await this.$A.AnnotationService.DeleteComment(c.id);
      if (response && response.success) {
        a.proofFileAnnotationComments.splice(
            a.proofFileAnnotationComments.indexOf(c),
            1
        );
        if (
            a.proofFileAnnotationComments.length === 0 ||
            a.proofFileAnnotationComments === null ||
            a.proofFileAnnotationComments === undefined
        ) {
          await this.deleteAnnotation(a);
        }
      }
    },
    getTimeFormatFromSeconds(t) {
      return new Date(t * 1000).toISOString().substr(11, 8);
    },
    rotateImage: async function (srcBase64, degrees) {
      const canvas = document.createElement('canvas');
      const ctx = canvas.getContext('2d');
      const image = new Image();

      image.src = srcBase64;
      await image.decode();

      canvas.width = degrees % 180 === 0 ? image.width : image.height;
      canvas.height = degrees % 180 === 0 ? image.height : image.width;

      ctx.translate(canvas.width / 2, canvas.height / 2);
      ctx.rotate(degrees * Math.PI / 180);
      ctx.drawImage(image, image.width / -2, image.height / -2);

      return canvas.toDataURL();
    },
    addNewCommentToAnnotationThread: async function (a, text) {
      if (!this.validateCanComment()) return;
      if (
          a.proofFileAnnotationComments === undefined ||
          a.proofFileAnnotationComments === null ||
          a.proofFileAnnotationComments.length === 0
      ) {
        if (this.curAnnotationType.type === DRAW) {
          const drawImage = this.$refs.proofCanvas.getDrawToolImage();
          if (!drawImage) {
            this.$A.AlertUser("You are creating a drawing comment but nothing has been drawn yet.");
            return;
          }

          const degrees = 360 - this.curFile.rotation;
          const rotatedImage = await this.rotateImage(drawImage, degrees);
          // console.log("original", drawImage);
          // console.log('rotated', rotatedImage);
          a.drawingLayerImageBase64Encoded = rotatedImage;
          a.contextImageBase64Encoded = rotatedImage;
        } else {
          a.drawingLayerImageBase64Encoded = "";
        }

        if (!a.contextImageBase64Encoded) {
          a.contextImageBase64Encoded = "";
        }

        // debugger
        let annotationCreated = await this.$A.AnnotationService.CreateAnnotation(a);


        if (annotationCreated && annotationCreated.id > 0) {
          if (annotationCreated.proofFileAnnotationComments === null) {
            annotationCreated.proofFileAnnotationComments = [];
          }
          a.id = annotationCreated.id;
          a.drawingLayerImageUrl = annotationCreated.drawingLayerImageUrl;
          this.editingAnnotationId = a.id;
        }
      }

      if (Number.isNaN(a.id)) {
        this.$A.AlertUser(this.$A.ReviewService.Lang("error-save-comment-later"));
        return;
      }

      a.proofFileAnnotationComments = a.proofFileAnnotationComments || [];
      if (a.proofFileAnnotationComments.length === 0 && a.curTime > 0) {
        text = "@" + this.getTimeFormatFromSeconds(a.curTime) + " \n" + text;
      }

      let newComment = {
        id: this.$A.GetUUID(),
        proofAnnotationId: a.id,
        proofId: a.proofId,
        proofVersionId: a.proofVersionId,
        proofFileId: a.proofFileId,
        userId: _.get(this.curUser, "id", 0),
        approverId: _.get(this.curApprover, "id", 0),
        ownerName: _.get(this.curApprover, "name", "") || _.get(this.curUser, "name", ""),
        ownerInitials: _.get(this.curApprover, "initials", "") || _.get(this.curUser, "initials", ""),
        createdAt: new Date(),
        text: text,
        drawingLayerImageBase64Encoded: ""
      };

      let savedComment = await this.$A.AnnotationService.CreateComment(
          newComment
      );

      if (savedComment !== null && savedComment.id > 0) {
        a.proofFileAnnotationComments.push(savedComment);
      } else {
        this.$A.AlertUser("Error Encountered, Please Save This Comment Later.");
      }

      this.$refs.proofCanvas.resetDrawing();
    },
    downloadThisFile: function () {
      let self = this;
      if (self.curFile === undefined || self.isLiveProofView) {
        return;
      }
      let file = self.curFile;
      if (this.curApprover && this.curApprover.token !== undefined) {
        this.$A.ProofFileService.DownloadSingle(
            this.publicId,
            this.curVersionId,
            file.id,
            0,
            this.curApprover.token,
            name
        );
      }
      if (this.curUser && this.curUser.apiToken !== undefined) {
        this.$A.ProofFileService.DownloadSingle(
            this.publicId,
            this.curVersionId,
            file.id,
            this.curUser.apiToken.token,
            "",
            name
        );
      }
    },
    downloadAllFiles: function () {
      if (this.proof === null) {
        return;
      }
      let name =
          this.proof.name +
          "_" +
          this.curVersionNumber +
          ".zip";
      if (this.curApprover && this.curApprover.token !== undefined) {
        this.$A.ProofFileService.DownloadAll(
            this.publicId,
            this.curVersionId,
            0,
            this.curApprover.token,
            name
        );
      }
      if (this.curUser && this.curUser.apiToken !== undefined) {
        this.$A.ProofFileService.DownloadAll(
            this.publicId,
            this.curVersionId,
            this.curUser.apiToken.token,
            "",
            name
        );
      }
    },
    disableContextMenu: function () {
      let self = this;
      let interval = setInterval(() => {
        if (self.$refs.reviewContainer) {
          self.$refs.reviewContainer.addEventListener("contextmenu", function (evt) {
            evt.preventDefault();
            return false;
          });
          clearInterval(interval);
        }
      }, 50);
    },
    refreshIsSmallMobile: function () {
      this.isSmallMobileView = window.innerWidth < 576;
    },
    handleResizeWindow: function () {
      this.width = window.innerWidth > 0 ? window.innerWidth : screen.width;
      this.menu = this.width < 1200 && this.width > 992 ? false : true;
      this.refreshIsSmallMobile();
    },
    setRecording: function (status) {
      this.isRecording = status;
    },
    reviewFile: function (file) {
      const proofCanvas = _.get(this, '$refs.proofCanvas', null);
      if (proofCanvas && proofCanvas.reviewFile(file)) {
        //set the currently active file
        this.files.forEach(function (i) {
          if (i.id === file.id) {
            i.active = true;
            i.viewed = true;
          } else {
            i.active = false;
          }
        });
        // cache viewed files
        const viewed = this.files.filter(f => f.viewed).map(f => f.id);
        this.$A.SaveDataCache("proof_version_id:" + this.curVersionId + "_viewed_files", viewed);

        let self = this;
        this.$nextTick(() => {
          self.curFile = file;
          self.$nextTick(() => {
            self.$refs.proofCanvas.updateOnFileChanged();
          });
        });
      }
    },
    setCurAnnotationType: function (annoType) {
      this.curAnnotationType = annoType;
      this.isDragEnabled = false;
    },
    toggleMenu: function () {
      this.menu = !this.menu;
      this.$nextTick(this.resize);
    },
    handleProofNavHeight: function (height) {
      this.proofNavHeight = height;
    },
    handleProofToolsHeight: function (height) {
      this.proofToolsHeight = height;
    },
    handleProofCommentsWidth: function (width) {
      this.proofCommentsWidth = width;
    },
    handleProofFilesWidth: function (width) {
      this.proofFilesWidth = width;
    },
    setEditingAnnotationId: function (id) {
      this.setActiveEditorAnnotationId(0);
      this.editingAnnotationId = id;
      this.menu = true;
      this.$refs.proofComments.showComments();
    },
    setActiveEditorAnnotationId: function (id) {
      this.activeEditorAnnotationId = id;
    },
    viewAnnotation: function (a) {
      this.$refs.proofCanvas.viewAnnotation({annotation: a, toggle: true});
    },
    setLiveProofView: function (values) {
      this.isLiveProofView = values.isLiveProofView;
      this.liveProofActiveUrl = values.liveProofActiveUrl;
    },
    setLiveProofSizeSelected: function (size) {
      this.liveProofSizeSelected = size;
      this.$refs.proofCanvas.updateLiveProofSize();
    },
    toggleLiveProofCommentsEnabled: function () {
      this.liveProofCommentsEnabled = !this.liveProofCommentsEnabled;
    },
    setMobileStatus: function () {
      if (window.innerWidth < 991) { //mobile
        this.isMobile = true;
        this.curAnnotationType = {
          docTypes: [DOCUMENT, VIDEO, IMAGE, HTML],
          type: POINT,
          title: () => this.$A.ReviewService.Lang("anno-single-click"),
          image: "/img/icon-doubleclick.svg",
          cursor: "/img/icon-doubleclick.png",
        };
      } else { // desktop
        this.isMobile = false;
      }
    },
    handleColorPicker: function (color) {
      this.colorPickerColor = color;
    },
    handleUndoDrawing: function () {
      this.$refs.proofCanvas.undoDrawing();
    },
    handleResetDrawing: function () {
      this.$refs.proofCanvas.resetDrawing();
    },
    handleDrawToolLineWidth: function (width) {
      this.drawToolLineWidth = width;
    },
    handleViewComments: function (viewComments) {
      this.$refs.proofCanvas.setViewComments(viewComments);
    },
    followApprover: function(approver) {
      const sameApprover = (approver.approverType && this.currentApprover && approver.id === this.currentApprover.id);
      const sameUser = (!approver.approverType && this.currentUser && approver.id === this.currentUser.id);
      if (sameApprover || sameUser) {
        return this.stopFollowingApprover();
      }
      const curAnnotation = this.$refs.proofCanvas.getEditingAnnotation();
      if (curAnnotation) {
        this.$refs.proofCanvas.setEditingAnnotation(curAnnotation, true);
      }
      this.followingApprover = approver;
      this.followingTargetId = approver.approverType ? `a:${approver.id}` : `u:${approver.id}`;
    },
    stopFollowingApprover: function() {
      this.followingApprover = null;
      this.followingTargetId = null;
    },
    moveToFollowingApprover: function(steps, waitLoops) {
      let self = this;

      if (waitLoops) {
        waitLoops--;
        this.$nextTick(() => {
          self.moveToFollowingApprover(steps, waitLoops);
        });
      }

      if (steps.version) {
        this.viewProofVersion(steps.version).then(() => {
          delete steps.version;
          self.moveToFollowingApprover(steps, 2);
        });
      } else if (steps.file) {
        self.reviewFile(steps.file);
        delete steps.file;
        self.moveToFollowingApprover(steps, 5);
      } else if (steps.page) {
        self.GoToPage(steps.page);
        delete steps.page;
        self.moveToFollowingApprover(steps, 1);
      } else if(steps.zoom) {
        self.updateZoom(steps.zoom);
        delete steps.zoom;
        self.moveToFollowingApprover(steps, 1);
      } else if (steps.zoomMode) {
        self.setZoomMode(steps.zoomMode);
        delete steps.zoomMode;
        self.moveToFollowingApprover(steps, 1);
      } else if (steps.rotation !== undefined) {
        self.setRotation(steps.rotation);
        delete steps.rotation;
        // self.moveToFollowingApprover(steps, 1);
      }
    },
    toggleDragMode: function(action) {
      this.isDragEnabled = action === RESET ? false : !this.isDragEnabled;
    },
  },
  computed: {
    tourSteps: function () {
      let self = this;
      return this.steps.filter(step => {
        if (step.disableOnFileTypes && self.curFile && step.disableOnFileTypes.includes(self.curFile.docTypeGroup)) {
          return false;
        }
        if (step.disableOnModes && step.disableOnModes.includes(self.mode)) {
          return false;
        }
        return true;
      }).map(step => {
        if (step.label && !step.content) {
          step.content = self.getLabel('tour_labels', step.label);
        }
        return step;
      });
    },
    curUsersTaggable: function () {
      let taggableUsers = [];
      let allApprovers = _.get(this.proof, 'currentVersion.stages', []).flatMap(stage => stage.approvers);
      let allReviewers = _.get(this.proof, 'currentVersion.stages', []).flatMap(stage => stage.reviewers);

      const curUsers = _.get(this.proof, 'engagedUsers', []);
      const curUserId = _.get(this.curUser, 'id');
      const curSender = _.get(this.proof, 'currentVersion.sender');
      const curSenderId = _.get(curSender, 'id');
      const curApproverId = _.get(this.curApprover, 'id');

      const toAddUsers = [];
      // add current user
      if (this.curUser) toAddUsers.push(this.curUser);
      // add sender
      if (curSender && curSenderId !== curApproverId) toAddUsers.push(curSender);
      // add reviewers
      toAddUsers.push(...allReviewers.filter(a => a.id !== curApproverId));
      // add engaged users
      toAddUsers.push(...curUsers.filter(a => a.id !== curApproverId));

      const toAddApprovers = [];
      // add current approver
      if (this.curApprover) toAddApprovers.push(this.curApprover);
      // add approvers
      toAddApprovers.push(...allApprovers.filter(a => a.id !== curUserId));

      // lookup map for those ids already added as taggable users
      const added = {};

      // adds a new taggable user if not added already
      const addTaggable = (a, type) => {
        if (added[a.id]) return;
        taggableUsers.push({
          type,
          name: a.name,
          color: a.color || this.getColor(a.id),
          email: a.email,
          initials: a.initials,
          publicId: a.publicId,
          id: a.id,
        });
        added[a.id] = true;
      };

      // add users
      toAddUsers.forEach(a => addTaggable(a, 'user'));

      // add approvers
      toAddApprovers.forEach(a => addTaggable(a, 'approver'));

      return taggableUsers;
    },
    curReviewers: function () {
      return _.get(this.proof, 'currentVersion.currentStage.reviewers', []);
    },
    curApprovers: function () {
      return _.get(this.proof, 'currentVersion.currentStage.approvers', []);
    },
    curUsers: function () {
      return _.get(this.proof, 'engagedUsers', []);
    },
    files: function () {
      return _.get(this.proof, 'currentVersion.versionFiles', []);
    },
    versions: function () {
      return _.get(this.proof, 'versionIds', []);
    },
    curVersionId: function () {
      return _.get(this.proof, 'currentProofVersionId', 1);
    },
    curFileId: function() {
      return _.get(this.curFile, 'id');
    },
    curVersionNumber: function () {
      return _.get(this.proof, 'currentVersion.versionNumber', 1);
    },
    currentUser: function () {
      return !this.curUser ? {id: 0} : this.curUser;
    },
    currentApprover: function () {
      return (this.curApprover && this.curApprover === null) ? {id: 0} : this.curApprover;
    },
    curSender: function () {
      return this.proof &&
      this.proof.currentVersion &&
      this.proof.currentVersion.sender
          ? this.proof.currentVersion.sender
          : {};
    },
    isModalOpen: function () {
      let approverResponseModal = this.$parent.$refs.approverResponseModal;
      return approverResponseModal && approverResponseModal.visible;
    },
    curZoom: function () {
      return _.get(this.curFile, 'zoom');
    },
    curZoomMode: function () {
      return _.get(this.curFile, 'zoomMode');
    },
    spaceAboveCanvas: function () {
      return this.proofNavHeight + this.proofToolsHeight;
    },
    spaceOnSidesOfCanvas: function () {
      return this.proofFilesWidth +
          (this.menu ? this.proofCommentsWidth : 70);
    },
    curAnnotations: function () {
      return _.get(this.curFile, 'proofFileAnnotations', []).map((a, i) => {
        a.isExpanded = (!a.resolved);
        a.index = i + 1;
        return a;
      });
    },
    usersColor: function () {
      const colors = {};
      this.curUsers.forEach(u => {
        colors[u.id] = u.color
      });
      this.curApprovers.forEach(u => {
        colors[u.id] = u.color
      });
      this.curReviewers.forEach(u => {
        colors[u.id] = u.color
      });
      this.curUsersTaggable.forEach(u => {
        colors[u.id] = u.color
      });

      if (this.curUser) colors[this.curUser.id] = this.curUser.color || this.getColor(this.curUser.id)
      if (this.curApprover) colors[this.curApprover.id] = this.curApprover.color || this.getColor(this.curApprover.id);

      return colors;
    },
    archived: function () {
      return _.get(this.proof, 'archived', false);
    },
    allowApproveWithChanges: function () {
      return _.get(this.proof, 'allowApproveWithChanges', false);
    },
    allowApproveIndividualFiles: function () {
      return _.get(this.proof, 'allowApproveIndividualFiles', false);
    },
    allowViewAllComments: function () {
      return _.get(this.proof, 'allowViewAllComments', false);
    },
    allowDownload: function () {
      return this.mode === PROOF_OWNER_MODE || _.get(this, 'proof.allowDownload', false);
    },
    showVertical() {
      return this.isCompareMode || this.isMobile;
    },
    isAudioFile() {
      return this.curFile && this.curFile.docTypeGroup === AUDIO;
    },
    isVideoFile() {
      return this.curFile && this.curFile.docTypeGroup === VIDEO;
    },
    isDragableFile() {    
      return this.curFile && this.curFile.docTypeGroup !== VIDEO && this.curFile.docTypeGroup !== AUDIO;
    },
    showRotateTool() {
      return !this.isAudioFile && !this.isVideoFile && !this.isLiveProofView;
    },
    showZoomTools() {
      return !this.isAudioFile && !this.isLiveProofView;
    },
    curFileActiveUsers() {
      return _.get(this, 'actives', []).filter(u =>
          u.proofVersionId === this.curVersionId
          && u.proofFileId === this.curFileId
          && _.get(u, 'pageToView') === _.get(this.curFile, 'pdf.pageToView', -1)
      );
    },
    curFileEvents() {
      return _.get(this, 'events', []).filter(e => {
        return true;
      });
    },
    shouldShowLeftSidebar() {
      return this.files.length > 1;
    }
  },
  watch: {
    actives: function (newVal) {
      if (this.followingTargetId) {
        const latest = newVal.find(u => u.targetId === this.followingTargetId);
        if (!latest) return;
        const steps = {};
        let move = false;

        if (latest.proofVersionId && this.curVersionId !== latest.proofVersionId) {
          steps.version = latest.proofVersionId;
          move = true;
        }
        if (latest.proofFileId && this.curFileId !== latest.proofFileId) {
          steps.file = this.files.find(f => f.id === latest.proofFileId);
          move = true;
        }
        const curPage = _.get(this.curFile, 'pdf.pageToView', -1);
        if (latest.pageToView && curPage !== latest.pageToView) {
          steps.page = latest.pageToView;
          move = true;
        }
        const curZoom = _.get(this.curFile, 'zoom');
        if (latest.zoom && curZoom !== latest.zoom) {
          steps.zoom = latest.zoom;
          move = true;
        }
        const curZoomMode = _.get(this.curFile, 'zoomMode');
        if (latest.zoomMode && curZoomMode !== latest.zoomMode) {
          steps.zoomMode = latest.zoomMode;
          move = true;
        }
        const rotation = _.get(this.curFile, 'rotation');
        if (rotation !== latest.rotation) {
          steps.rotation = latest.rotation;
          move = true;
        }
        if (move) {
          this.moveToFollowingApprover(steps);
        }
      }
    },
  }
};
</script>

<template>
  <div class="main-wrapper d-flex flex-column">
    <div v-if="accessDenied" class="container-fluid" :style="{ height: '100%' }">
      <h2>{{ accessDeniedMessage }}</h2>
    </div>
    <!-- proof-view-navigation -->
    <proof-view-navigation
        ref="proofNav"
        v-if="!accessDenied && isReviewMode"
        :disabled="!!followingApprover"
        :avatar="curLogo"
        :cur-version-id="curVersionId"
        :versions="versions"
        :approvers="curApprovers"
        :users="curUsers"
        :reviewers="curReviewers"
        :actives="actives"
        :cur-display-lang="curDisplayLang"
        :is-review-mode="isReviewMode"
        :is-mobile="isMobile"
        :approver-feedback="approverFeedback"
        :archived="archived"
        :mode="mode"
        :show-approved-button="showApprovedButton"
        :allow-approve-with-changes="allowApproveWithChanges"
        :allow-approve-individual-files="allowApproveIndividualFiles"
        :display-all-approvers="allowViewAllComments"
        :allow-download="allowDownload"
        @height-changed="handleProofNavHeight"
        @on-download-this-file="downloadThisFile"
        @on-download-all-files="downloadAllFiles"
        @on-version-change="viewProofVersion"
        @start-tour="$tours['review-screen'].start()"
        @follow-approver="followApprover"
        v-on="$listeners"
    />
    <div v-if="!accessDenied"
         :class="{'' : showVertical, 'd-flex flex-fill  flex-lg-row flex-column ' : !showVertical}"
         class="bg-light overflow-auto position-relative"
         @resize.window="handleResizeWindow" @init="handleResizeWindow">
      <!-- proof-view-side-bar-left -->
      <proof-view-side-bar-left
          :files="files"
          :active-file="curFile"
          @set-active-file="reviewFile"
          @width-changed="handleProofFilesWidth"
          :is-compare-mode="isCompareMode"
          :is-mobile="isMobile"
          :disabled="!!followingApprover"
          v-if="shouldShowLeftSidebar || this.isCompareMode"
      />
      <!--  proof tools and canvas  -->
      <main :class="{'canvas-wrapper-compare' : isCompareMode, 'pl-73' : !showVertical && shouldShowLeftSidebar}"
            class="canvas-container canvas-wrapper flex-fill overflow-hidden position-relative">
        <!-- proof-view-tools -->
        <proof-view-tools
            ref="proofTools"
            v-if="!isAudioFile && !followingApprover"
            :disabled="!!followingApprover"
            :cur-file="curFile"
            :is-mobile="isMobile"
            :is-compare-mode="isCompareMode"
            :cur-annotation-type="curAnnotationType"
            :set-cur-annotation-type="setCurAnnotationType"
            :cur-zoom="curZoom"
            :cur-zoom-mode="curZoomMode"
            :update-zoom="updateZoom"
            :rotate="rotate"
            :toggle-zoom-mode="toggleZoomMode"
            :go-to-next-page="GoToNextPage"
            :go-to-previous-page="GoToPreviousPage"
            :go-to-page="GoToPage"
            :is-live-proof-view="isLiveProofView"
            :live-proof-size-selected="liveProofSizeSelected"
            :live-proof-predefined-sizes="liveProofPredefinedSizes"
            :live-proof-comments-enabled="liveProofCommentsEnabled"
            :is-recording="isRecording"
            :show-zoom-tools="showZoomTools"
            :show-rotate-tool="showRotateTool"
            :toggle-drag-mode="toggleDragMode"
            :is-drag-enabled="isDragEnabled"
            :is-dragable-file="isDragableFile"
            :is-third-wheel-zoom-active="isThirdWheelZoomActive"
            :mode="mode"
            @height-changed="handleProofToolsHeight"
            @set-live-proof-size-selected="setLiveProofSizeSelected"
            @toggle-live-proof-comments="toggleLiveProofCommentsEnabled"
        />
        <!-- proof-view-canvas -->
        <proof-view-canvas
            ref="proofCanvas"
            id="review-canvas"
            :cur-version-id="curVersionId"
            :label="label"
            :space-above="spaceAboveCanvas"
            :space-on-sides="spaceOnSidesOfCanvas"
            :active-file="curFile"
            :files="files"
            :mode="mode"
            :viewMode="viewMode"
            :isReviewMode="isReviewMode"
            :isCompareMode="isCompareMode"
            :isMobile="isMobile"
            :curUser="curUser"
            :curUsers="curUsers"
            :active-users="curFileActiveUsers"
            :proof-active-users="actives"
            :events="curFileEvents"
            :curApprover="curApprover"
            :curApprovers="curApprovers"
            :curAccount="curAccount"
            :curReviewers="curReviewers"
            :is-modal-open="isModalOpen"
            :cur-annotation-type="curAnnotationType"
            :delete-annotation="deleteAnnotation"
            :add-annotation="addAnnotation"
            :cur-annotations="curAnnotations"
            :color-picker-color="colorPickerColor"
            :draw-tool-line-width="drawToolLineWidth"
            :live-proof-size-selected="liveProofSizeSelected"
            :live-proof-predefined-sizes="liveProofPredefinedSizes"
            :live-proof-comments-enabled="liveProofCommentsEnabled"
            :users-color="usersColor"
            :following-approver="followingApprover"
            :allow-view-all-comments="allowViewAllComments"
            :is-drag-enabled="isDragEnabled"
            :isThirdWheelZoomActive="isThirdWheelZoomActive"
            @reset-drag-mode="toggleDragMode"
            @on-recording="setRecording"
            @on-editing-annotation-id="setEditingAnnotationId"
            @on-live-proof-size-selected="setLiveProofSizeSelected"
            @on-live-proof-view="setLiveProofView"
            @set-mobile-status="setMobileStatus"
            @cursor-update="event => $emit('cursor-update', event)"
            @stop-following-approver="stopFollowingApprover"
            @on-load-proof-version="viewProofVersion"
        />
      </main>
      <!-- proof-view-side-bar-right  -->
      <proof-view-side-bar-right ref="proofComments"
          :label="label"
          :disabled="!!followingApprover"
          :active-file="curFile"
          :cur-user="curUser"
          :cur-approver="curApprover"
          :menu="menu"
          :proof-view-version="proofViewVersion"
          :cur-annotations="curAnnotations"
          :cur-annotation-type="curAnnotationType"
          :active-comment-id="editingAnnotationId"
          :cur-users-taggable="curUsersTaggable"
          :comment-file-api="commentFileApi"
          :is-live-proof-view="isLiveProofView"
          :live-proof-active-url="liveProofActiveUrl"
          :live-proof-size-selected="liveProofSizeSelected"
          :is-compare-mode="isCompareMode"
          :is-mobile="isMobile"
          :users-color="usersColor"
          :avatar="curLogo"
          :cur-account="curAccount"
          :active-editor-annotation-id="activeEditorAnnotationId"
          :allow-view-all-comments="allowViewAllComments"
          @on-set-recording="setRecording"
          @on-toggle="toggleMenu"
          @on-width-changed="handleProofCommentsWidth"
          @on-save-comment="addNewCommentToAnnotationThread"
          @on-update-comment="updateComment"
          @on-delete-comment="deleteComment"
          @on-set-active-comment="viewAnnotation"
          @on-editor-show="setActiveEditorAnnotationId"
          @on-undo-drawing="handleUndoDrawing"
          @on-reset-drawing="handleResetDrawing"
          @on-draw-color-change="handleColorPicker"
          @on-draw-line-width-change="handleDrawToolLineWidth"
          @on-showing-comments="handleViewComments"
      />
    </div>
    <!--   tour   -->
    <v-tour v-if="isReviewMode && !isMobile"
        v-show="curFile"
        name="review-screen"
        :steps="tourSteps"
        :callbacks="tourCallbacks"
        :options="{labels: tourLabels}"
    ></v-tour>
  </div>
</template>

<style scoped>
.canvas-wrapper-compare {
  margin: 0px !important;
  padding: 0px !important;
}
.canvas-container{
  container-type: inline-size;
}
.pl-73 {
  padding-left: 73px;
}
@media(min-width:992px) {
  .main-wrapper{
    height: 100vh;
  }
}
@media(max-width:1200px) and (min-width:992px){
  .canvas-wrapper{
    margin-right: 72px;
  }
}
@media(max-width:992px){
  .canvas-wrapper{
    height: auto !important;
    display: flex !important;
    flex-direction: column !important;
  }
  .navbar-btn-container{
    width: 100%;
    border-right: 0px !important;
    border-top:  1px solid #EBEBED;
  }
}
</style>



